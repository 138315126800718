.rdp-day_selected:not([disabled]) { 
    background-color: #00937C;
    color: #F5F5F5;
    opacity: 1;
}

.rdp-day_selected:hover:not([disabled]) { 
    background-color: #00937C;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: antiquewhite;
}

.rdp-day_today { 
    font-weight: bold;
    font-size: 140%; 
}